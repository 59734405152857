import React, { useEffect, useState } from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';
import TeamMarkAttendance from './TeamMarkAttendance';
import Teamattendance from './Teamattendance';
import AdminTeamleader from './AdminTeamleader';
import Adminemployees from './Adminemployees';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';
import EmployeeActivity from './EmployeeActivity';
import TeamleaderActivity from './TeamleaderActivity';
import Leave from './Leave';
import Tasks from './Tasks';
export default function AdminDashboard() {
    const [activeTab, setActiveTab] = useState('dashboard');
    const [teamLeaderCount, setTeamLeaderCount] = useState(0);
    const [employeeCount, setEmployeeCount] = useState(0);
    const [currentTime, setCurrentTime] = useState(new Date());
    const navigate = useNavigate();
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const [selectedTeamleader, setSelectedTeamleader] = useState(null);

    const token = localStorage.getItem('teamLeaderToken');

    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('teamLeaderToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('teamLeaderToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    useEffect(() => {
        // Fetch team leaders count
        fetch('https://mrv1.indianwelfarefoundation.org.in/countteam',{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => setTeamLeaderCount(data.count))
            .catch(error => console.error('Error fetching team leader count:', error));

        // Fetch employee count
        fetch('https://mrv1.indianwelfarefoundation.org.in/countemp',{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => setEmployeeCount(data.count))
            .catch(error => console.error('Error fetching employee count:', error));

        // Update time every second
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'dashboard':
                return (
                    <div className="dashboard-content">
                        <div className="time-container">
                            <div className="day">
                                {currentTime.toLocaleDateString('en-US', { weekday: 'long' })}
                            </div>
                            <div className="time">
                                {currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                            </div>
                        </div>
                        <div className="stats-container">
                            <div className="stat-box team-leaders">
                                <h3>Team Leaders</h3>
                                <p>{teamLeaderCount}</p>
                            </div>
                            <div className="stat-box employees">
                                <h3>Employees</h3>
                                <p>{employeeCount}</p>
                            </div>
                        </div>
                    </div>
                );
            case 'mark-attendance':
                return <TeamMarkAttendance />;
            case 'attendance':
                return <Teamattendance />;
                case 'Tasks':
                return <Tasks />;
            case 'Leave':
                return <Leave/>
           
                // Add the component for team-leaders
               
                case 'employees':
                    return (
                        <Adminemployees
                            onViewActivity={(employee) => {
                                setSelectedEmployee(employee);
                                setActiveTab('employee-activity');
                            }}
                        />
                    );
                case 'employee-activity':
                    return (
                        <EmployeeActivity
                            employee={selectedEmployee}
                            onBack={() => setActiveTab('employees')}
                        />
                    );
            default:
                return null;
        }
    };
const adminname=localStorage.getItem('teamleadername')
    const logOut = () => {
        localStorage.removeItem('teamLeaderToken');
        navigate('/');
    };

    return (
        <div className="admin-dashboard">
            <aside className="sidebar">
                <div className="logo">{adminname}</div>
                <nav className="nav">
                    <ul>
                        <li
                            className={activeTab === 'dashboard' ? 'active' : ''}
                            onClick={() => setActiveTab('dashboard')}
                        >
                            Dashboard
                        </li>
                        <li
                            className={activeTab === 'mark-attendance' ? 'active' : ''}
                            onClick={() => setActiveTab('mark-attendance')}
                        >
                            Mark Attendance
                        </li>
                        <li
                            className={activeTab === 'attendance' ? 'active' : ''}
                            onClick={() => setActiveTab('attendance')}
                        >
                            Attendance
                        </li>
                        <li
                            className={activeTab === 'Leave' ? 'active' : ''}
                            onClick={() => setActiveTab('Leave')}
                        >
                            Leave
                        </li>
                        <li
                            className={activeTab === 'Tasks' ? 'active' : ''}
                            onClick={() => setActiveTab('Tasks')}
                        >
                            Tasks
                        </li>
                        <li
                            className={activeTab === 'employees' ? 'active' : ''}
                            onClick={() => setActiveTab('employees')}
                        >
                            Employees
                        </li>
                    </ul>
                </nav>
            </aside>
            <main className="main-content">
                <header className="header">
                    <div className="search-bar">
                       
                    </div>
                    <div className="profile" style={{cursor:'pointer'}} onClick={logOut}>Logout</div>
                </header>
                <section className="content-area">
                    {renderContent()}
                </section>
            </main>
        </div>
    );
}
