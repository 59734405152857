import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Leave() {
  const [activeTab, setActiveTab] = useState('request');
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  const recordsPerPage = 5;
  const navigate=useNavigate('')
  // useEffect(() => {
  //     const checkToken = async () => {
  
  
  //       if (!token) {
  //         navigate('/');
  //         return;
  //       }
  
  //       try {
  //         const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
  //           method: 'POST',
  //           headers: {
  
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({ token }),
  //         });
  
  //         const data = await response.json();
  
  //         if (data.isValid) {
            
  //         } else {
  //           localStorage.removeItem('token')
            
  //           navigate('/');
  //         }
  //       } catch (error) {
  //         localStorage.removeItem('token')
  
         
  //         navigate('/');
  //       }
  //     };
  
  //     checkToken();
  //   }, [navigate]);
  useEffect(() => {
    if (activeTab === 'status') {
      fetchLeaveRequests();
    }
  }, [activeTab]);

  // Check if logged in as employee or team leader
const emp_id = localStorage.getItem('employeeid') || localStorage.getItem('teamid');
console.log(emp_id)
// If no emp_id found, handle it accordingly (e.g., redirect to login or show an error)
if (!emp_id) {
    console.error("No employee ID or team leader ID found in local storage.");
    // You could redirect to login or handle this case as needed
    // navigate('/login');
}

const token = localStorage.getItem('employeeToken');


  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.get(`https://mrv1.indianwelfarefoundation.org.in/fetchleave/${emp_id}`,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      setLeaveRequests(response.data);
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const start_date = e.target.elements['start-date'].value;
    const end_date = e.target.elements['end-date'].value;
    const title = e.target.elements['title'].value;
    const description = e.target.elements['description'].value;

    try {
      await axios.post('https://mrv1.indianwelfarefoundation.org.in/leave', {
        emp_id,
        start_date,
        end_date,
        title,
        description,
      },{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      alert('Leave request submitted successfully!');
      setActiveTab('status');
    } catch (error) {
      console.error("Error submitting leave request:", error);
    }
  };

  const renderForm = () => (
    <form style={styles.leaveForm} onSubmit={handleSubmit}>
      <div style={styles.row}>
        <div style={styles.inputGroup}>
          <label htmlFor="start-date" style={styles.label}>Start Date:</label>
          <input type="date" id="start-date" style={styles.input} required />
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="end-date" style={styles.label}>End Date:</label>
          <input type="date" id="end-date" style={styles.input} required />
        </div>
      </div>
      <div style={styles.inputGroup}>
        <label htmlFor="title" style={styles.label}>Leave Title:</label>
        <input type="text" id="title" style={styles.input} required />
      </div>
      <div style={styles.inputGroup}>
        <label htmlFor="description" style={styles.label}>Leave Description:</label>
        <textarea id="description" rows="4" style={styles.textarea} required></textarea>
      </div>
      <button type="submit" style={styles.submitButton}>Submit Leave Request</button>
    </form>
  );

  const renderAccordion = () => {
    if (leaveRequests.length === 0) {
      return <div style={styles.noData}>No leave requests found.</div>;
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = leaveRequests.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(leaveRequests.length / recordsPerPage);

    return (
      <>
        <div style={styles.accordion} id="leaveAccordion">
          {currentRecords.map((leave, index) => (
            <div style={styles.accordionItem} key={index}>
              <h2 style={styles.accordionHeader}>
                <button
                  type="button"
                  onClick={() => toggleAccordion(index)}
                  style={styles.accordionButton}
                >
                  {leave.start_date} to {leave.end_date} - Status: {leave.status}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                style={{ ...styles.accordionCollapse, ...(leave.expanded ? styles.expanded : {}) }}
              >
                <div style={styles.accordionBody}>
                  <h4>{leave.title}</h4>
                  <p>{leave.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div style={styles.pagination}>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              style={{ ...styles.paginationButton, ...(index + 1 === currentPage ? styles.activePaginationButton : {}) }}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </>
    );
  };

  const toggleAccordion = (index) => {
    setLeaveRequests((prevRequests) =>
      prevRequests.map((request, i) =>
        i === index ? { ...request, expanded: !request.expanded } : { ...request, expanded: false }
      )
    );
  };

  return (
    <div style={styles.leaveContainer}>
      <div style={styles.tabs}>
        <div
          style={activeTab === 'request' ? {...styles.tab, ...styles.activeTab} : styles.tab}
          onClick={() => setActiveTab('request')}
        >
          Leave Request
        </div>
        <div
          style={activeTab === 'status' ? {...styles.tab, ...styles.activeTab} : styles.tab}
          onClick={() => setActiveTab('status')}
        >
          Leave Status
        </div>
      </div>
      {activeTab === 'request' && renderForm()}
      {activeTab === 'status' && renderAccordion()}
    </div>
  );
}

const styles = {
  leaveContainer: {
    maxWidth: '800px',
    margin: '20px auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '10px',
    backgroundColor: '#e0e0e0',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '8px 8px 0 0',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  },
  activeTab: {
    backgroundColor: '#007bff',
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
  },
  leaveForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  inputGroup: {
    flex: 1,
    marginRight: '10px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: '14px',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: '14px',
    resize: 'vertical',
  },
  submitButton: {
    padding: '10px 15px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  },
  accordion: {
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  accordionItem: {
    marginBottom: '10px',
  },
  accordionHeader: {
    margin: '0',
  },
  accordionButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '4px',
    outline: 'none',
  },
  accordionCollapse: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
  expanded: {
    maxHeight: '500px',
  },
  accordionBody: {
    padding: '15px',
    borderTop: '1px solid #ddd',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  paginationButton: {
    padding: '8px 12px',
    margin: '0 5px',
    border: '1px solid #007bff',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#007bff',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  },
  activePaginationButton: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  noData: {
    textAlign: 'center',
    padding: '20px',
    fontSize: '18px',
    color: '#999',
  },
};
