import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Employeemarkattendance() {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [leaveTaken, setLeaveTaken] = useState(false);
    const [canEndWork, setCanEndWork] = useState(false);
    const [workCompleted, setWorkCompleted] = useState(false);
    const [holidays, setHolidays] = useState([]);
    const [todayHoliday, setTodayHoliday] = useState('');
    const [permissionTime, setPermissionTime] = useState('9:00 AM'); // Default to 9:00 AM
    const [canStartWork, setCanStartWork] = useState(false); // Flag to control when "Start Work" is enabled
    const navigate = useNavigate();
    const token = localStorage.getItem('employeeToken');
    
    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('employeeToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('employeeToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
    const formatTime12Hour = (date) => {
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
    };

    const formatTime24Hour = (timeStr) => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        if (modifier === 'PM' && hours < 12) hours += 12;
        if (modifier === 'AM' && hours === 12) hours = 0;
        return new Date(0, 0, 0, hours, minutes, 0);
    };

    const isSunday = (date) => {
        return date.getDay() === 0; // 0 is Sunday
    };

    const fetchAttendanceData = async () => {
        const emp_id = localStorage.getItem('employeeid');
        const today = new Date().toISOString().split('T')[0];

        setLoading(true);
        try {
            const response = await fetch(`https://mrv1.indianwelfarefoundation.org.in/empattendance?emp_id=${emp_id}&date=${today}`,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();

            if (data && data.start_time === 'leave') {
                setLeaveTaken(true);
                return; // If on leave, no need to continue fetching start/end times
            }

            if (data && data.start_time) {
                const startDate = formatTime24Hour(data.start_time);
                setStartTime(startDate);
                calculateEndTime(startDate);
            }

           

            if (data && data.end_time) {
                setWorkCompleted(true);
            }
        } catch (error) {
            console.error('Error fetching attendance data:', error);
            alert('Error fetching attendance data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchHolidays = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/holidays',{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const holidaysData = await response.json();
            setHolidays(holidaysData);
            checkTodayHoliday(holidaysData);
        } catch (error) {
            console.error('Error fetching holidays data:', error);
            alert('Error fetching holidays data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const checkTodayHoliday = (holidaysList) => {
        const today = new Date();
        const formattedToday = `${today.getDate()}-${today.toLocaleString('default', { month: 'short' })}`;
        const todayHolidayObj = holidaysList.find(holiday => holiday.date === formattedToday);
        if (todayHolidayObj) {
            setTodayHoliday(todayHolidayObj.name);
        }
    };

    const calculateEndTime = (startDate) => {
        const endDate = new Date(startDate.getTime() + 8 * 60 * 60 * 1000);
        setEndTime(endDate);
        checkEndWorkTime();
    };

    const startWork = async () => {
        const now = new Date();
        const emp_id = localStorage.getItem('employeeid');

        setLoading(true);
        try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/empstartwork', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`
                },
                body: JSON.stringify({ emp_id, startTime: formatTime12Hour(now) }),
            });

            if (!response.ok) {
                throw new Error('Failed to start work. Attendance not updated.');
            }

            setStartTime(now);
            calculateEndTime(now);
            scheduleAutoEndWork(); // Schedule auto-end work at 11:58 PM
        } catch (error) {
            console.error('Error during start work:', error);
            alert('Attendance not updated. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const endWork = async (auto = false) => {
        const now = new Date();
        const emp_id = localStorage.getItem('employeeid');
        const endTime = auto ? '11:58 PM' : formatTime12Hour(now);

        setLoading(true);
        try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/empendwork', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`
                },
                body: JSON.stringify({ emp_id, endTime }),
            });

            if (!response.ok) {
                throw new Error('Failed to end work. Attendance not updated.');
            }

            setWorkCompleted(true);
        } catch (error) {
            console.error('Error during end work:', error);
            alert('End work not updated. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const scheduleAutoEndWork = () => {
        const now = new Date();
        const targetEnd = new Date(now);
        targetEnd.setHours(23, 58, 0, 0); // Set to 11:58 PM

        const timeUntilEndWork = targetEnd - now;

        if (timeUntilEndWork > 0) {
            setTimeout(() => {
                if (!workCompleted) {
                    endWork(true); // Automatically end work at 11:58 PM if not already ended
                }
            }, timeUntilEndWork);
        }
    };

    const takeLeave = async () => {
        const emp_id = localStorage.getItem('employeeid');

        setLoading(true);
        try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/takeleave', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ emp_id }),
            });

            if (!response.ok) {
                throw new Error('Failed to take leave. Attendance not updated.');
            }

            setLeaveTaken(true);
        } catch (error) {
            console.error('Error during take leave:', error);
            alert('Leave not updated. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const autoMarkLeaveIfNoStartBy930AM = () => {
        const now = new Date();
        const targetTime = new Date(now);
        targetTime.setHours(9, 30, 0, 0); // Set to 9:30 AM

        const timeUntilTarget = targetTime - now;
        if (timeUntilTarget > 0) {
            setTimeout(() => {
                if (!startTime && !leaveTaken && !workCompleted) {
                    takeLeave(); // Mark as leave if no work start by 9:30 AM
                }
            }, timeUntilTarget);
        }
    };

    const checkStartWorkTime = (permissionTime) => {
        const currentTime = new Date();
        const permissionDateTime = formatTime24Hour(permissionTime);

        // Enable the "Start Work" button only if current time >= permission time
        if (currentTime >= permissionDateTime) {
            setCanStartWork(true);
        } else {
            const timeUntilPermission = permissionDateTime - currentTime;
            setTimeout(() => {
                setCanStartWork(true); // Enable the button at the permission time
            }, timeUntilPermission);
        }
    };

    const checkEndWorkTime = () => {
        const now = formatTime12Hour(new Date());

        if (formatTime24Hour(now) >= endTime) {
            setCanEndWork(true);
        }
    };

    useEffect(() => {
        const today = new Date();
        if (!isSunday(today)) {
            fetchAttendanceData();
            fetchHolidays();
            autoMarkLeaveIfNoStartBy930AM();
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(checkEndWorkTime, 10);
        return () => clearInterval(interval);
    }, [endTime]);

    const handleEndWorkClick = () => {
        endWork(false); // Pass false to indicate this is not auto-end work
    };
    const renderMarkAttendance = () => {
    if (loading) {
        return <div className="loading-spinner">Loading...</div>; // Replace with a spinner component
    }

    const today = new Date();
    const isTodaySunday = isSunday(today);

    if (isTodaySunday) {
        return (
            <div className="mark-attendance">
                <p>Enjoy the Sunday!</p>
            </div>
        );
    }

    if (todayHoliday) {
        return (
            <div className="mark-attendance">
                <p>Today is a holiday: {todayHoliday}</p>
            </div>
        );
    }

    if (leaveTaken) {
        return (
            <div className="mark-attendance">
                <p>You are on leave for today.</p>
            </div>
        );
    }

    if (workCompleted) {
        return (
            <div className="mark-attendance">
                <p>Today's work has been completed, enjoy the day.</p>
            </div>
        );
    }

    if (!startTime) {
        return (
            <div className="mark-attendance">
                <button className="start-button" onClick={startWork} >
                    Start Work
                </button>
               
            </div>
        );
    }

    if (startTime) {
        return (
            <div className="mark-attendance">
                <p>Work started at: {formatTime12Hour(startTime)}</p>
                {canEndWork ? (
                    <button className="end-button" onClick={handleEndWorkClick}>
                        End Work
                    </button>
                ) : (
                    <p>You can end work at: {formatTime12Hour(endTime)}</p>
                )}
            </div>
        );
    }
    }
return <div>{renderMarkAttendance()}</div>;
}