import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function ManageLeave() {
  const [activeTab, setActiveTab] = useState('Pending');
  const [leaveRequests, setLeaveRequests] = useState({
    Pending: [],
    Approved: [],
    Rejected: []
  });
  const [holidays, setHolidays] = useState([]);
  const navigate=useNavigate('')
  useEffect(() => {
      const checkToken = async () => {
  
  
        if (!token) {
          navigate('/');
          return;
        }
  
        try {
          const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
            method: 'POST',
            headers: {
  
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
  
          const data = await response.json();
  
          if (data.isValid) {
            
          } else {
            localStorage.removeItem('adminToken')
            
            navigate('/');
          }
        } catch (error) {
          localStorage.removeItem('adminToken')
  
         
          navigate('/');
        }
      };
  
      checkToken();
    }, [navigate]);
  useEffect(() => {
    fetchLeaveRequests();
    fetchHolidays();
  }, []);
  const token = localStorage.getItem('adminToken');

  // Fetch leave requests from the server
  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.get('https://mrv1.indianwelfarefoundation.org.in/leavestatus',{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      const allRequests = response.data;
      console.log(allRequests);

      const categorizedRequests = {
        Pending: allRequests.filter((req) => req.status === 'Pending'),
        Approved: allRequests.filter((req) => req.status === 'Approved'),
        Rejected: allRequests.filter((req) => req.status === 'Rejected')
      };

      setLeaveRequests(categorizedRequests);
    } catch (error) {
      console.error('Error fetching leave requests:', error);
    }
  };

  // Fetch holiday list from the server
  const fetchHolidays = async () => {
    try {
      const response = await axios.get('https://mrv1.indianwelfarefoundation.org.in/holidays',{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      
      // Use more explicit date parsing to avoid timezone issues
      const holidayData = response.data.map((holiday) => {
        console.log(response.data);
        
        // Parse the holiday date from the format '7-sep' into proper format
        const [day, monthStr] = holiday.date.split('-');
        const monthMap = {
          'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05',
          'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10',
          'Nov': '11', 'Dec': '12'
        };
  
        const month = monthMap[monthStr]; // Get numeric month
        const year = new Date().getFullYear(); // Assuming current year
        
        // Construct the date string explicitly in 'YYYY-MM-DD' format
        const formattedDate = `${year}-${month}-${day.padStart(2, '0')}`; // Ensure day is 2 digits
        
        // Return as a Date object
        return new Date(formattedDate);
      });
  
      setHolidays(holidayData);
    } catch (error) {
      console.error('Error fetching holidays:', error);
    }
  };
  

  // Update the leave status
  const updateLeaveStatus = async (id, status, start_date, end_date, emp_id) => {
    try {
      console.log('Status Update Payload:', { id, status });

      // Approve the leave
      await axios.post('https://mrv1.indianwelfarefoundation.org.in/leaverequests', {
        id,
        status
      },{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

      // If status is approved, filter out holidays and Sundays and send only actual working days
      if (status === 'Approved') {
        const workingDays = filterWorkingDays(start_date, end_date, holidays);
        if (workingDays.length > 0) {
          console.log('Leave Update Payload:', {
            emp_id,
            start_date: workingDays[0],
            end_date: workingDays[workingDays.length - 1]
          });
          await axios.post('https://mrv1.indianwelfarefoundation.org.in/updateLeaveRecords', {
            emp_id,
            start_date: workingDays[0],
            end_date: workingDays[workingDays.length - 1]
          },{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        } else {
          console.log('No working days found in the given date range.');
        }
      }

      fetchLeaveRequests(); // Refresh leave request list
    } catch (error) {
      console.error('Error updating leave status:', error);
    }
  };

  // Helper function to filter out Sundays and holidays
  const filterWorkingDays = (startDate, endDate, holidays) => {
    console.log(`Filtering working days between ${startDate} and ${endDate}`);
    
    const date = new Date(startDate);
    const end = new Date(endDate);
    const validDays = [];
  
    console.log("Initial date:", date);
    console.log("End date:", end);
    console.log("Holiday dates:", holidays.map(holiday => holiday.toISOString().split('T')[0])); // Log holidays
  
    // Loop through each day in the range from startDate to endDate
    while (date <= end) {
      const formattedDate = date.toISOString().split('T')[0];
      const isSunday = date.getDay() === 0; // Check if it's a Sunday
      const isHoliday = holidays.some(
        (holiday) => holiday.toISOString().split('T')[0] === formattedDate
      ); // Check if it's a holiday
  
      console.log(`Checking date: ${formattedDate} (Sunday: ${isSunday}, Holiday: ${isHoliday})`);
  
      if (!isSunday && !isHoliday) {
        console.log(`Adding valid working day: ${formattedDate}`);
        validDays.push(formattedDate); // Add valid working day
      } else {
        if (isSunday) {
          console.log(`${formattedDate} is a Sunday, skipping.`);
        }
        if (isHoliday) {
          console.log(`${formattedDate} is a holiday, skipping.`);
        }
      }
  
      date.setDate(date.getDate() + 1); // Move to the next day
      console.log(`Next date to check: ${date.toISOString().split('T')[0]}`);
    }
  
    console.log("Valid working days:", validDays);
    return validDays;
  };
  
  // Render content based on the current tab (Pending, Approved, Rejected)
  const renderContent = () => {
    const currentRequests = leaveRequests[activeTab];

    if (currentRequests.length === 0) {
      return <div style={styles.noData}>No {activeTab.toLowerCase()} leave requests found.</div>;
    }


    return (
      <div style={styles.accordion}>
        {currentRequests.map((leave, index) => (
          <div style={styles.accordionItem} key={index}>
            <h2 style={styles.accordionHeader}>
              <button
                type="button"
                onClick={() => toggleAccordion(index)}
                style={styles.accordionButton}
              >
                {leave.start_date} to {leave.end_date} - {leave.name}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              style={{ ...styles.accordionCollapse, ...(leave.expanded ? styles.expanded : {}) }}
            >
              <div style={styles.accordionBody}>
                <h4>{leave.title}</h4>
                <p>{leave.description}</p>
                {activeTab === 'Pending' && (
                  <div style={styles.actionButtons}>
                    <button
                      style={styles.approveButton}
                      onClick={() => updateLeaveStatus(leave.id, 'Approved', leave.start_date, leave.end_date, leave.emp_id)}
                    >
                      Approve
                    </button>
                    <button
                      style={styles.rejectButton}
                      onClick={() => updateLeaveStatus(leave.id, 'Rejected', leave.start_date, leave.end_date, leave.emp_id)}
                    >
                      Reject
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const toggleAccordion = (index) => {
    setLeaveRequests((prevRequests) => ({
      ...prevRequests,
      [activeTab]: prevRequests[activeTab].map((request, i) =>
        i === index ? { ...request, expanded: !request.expanded } : { ...request, expanded: false }
      )
    }));
  };

  return (
    <div style={styles.leaveContainer}>
      <div style={styles.buttonsContainer}>
        <button
          style={activeTab === 'Pending' ? {...styles.button, ...styles.activeButton} : styles.button}
          onClick={() => setActiveTab('Pending')}
        >
          Pending Leaves
        </button>
        <button
          style={activeTab === 'Approved' ? {...styles.button, ...styles.activeButton} : styles.button}
          onClick={() => setActiveTab('Approved')}
        >
          Approved Leaves
        </button>
        <button
          style={activeTab === 'Rejected' ? {...styles.button, ...styles.activeButton} : styles.button}
          onClick={() => setActiveTab('Rejected')}
        >
          Rejected Leaves
        </button>
      </div>
      {renderContent()}
    </div>
  );
}

 

const styles = {
  leaveContainer: {
    maxWidth: '800px',
    margin: '20px auto',
    backgroundColor: '#f8f9fa',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  button: {
    flex: 1,
    padding: '10px',
    backgroundColor: '#e9ecef',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '10px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    margin: '0 5px',
  },
  activeButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
  },
  accordion: {
    borderRadius: '10px',
    border: '1px solid #ddd',
  },
  accordionItem: {
    marginBottom: '10px',
  },
  accordionHeader: {
    margin: '0',
  },
  accordionButton: {
    width: '100%',
    padding: '15px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '10px',
    outline: 'none',
    transition: 'background-color 0.3s ease',
  },
  accordionButtonHovered: {
    backgroundColor: '#0056b3',
  },
  accordionCollapse: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
  expanded: {
    maxHeight: '500px',
  },
  accordionBody: {
    padding: '20px',
    borderTop: '1px solid #ddd',
    backgroundColor: '#f8f9fa',
    borderRadius: '0 0 10px 10px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  approveButton: {
    padding: '10px 15px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  },
  rejectButton: {
    padding: '10px 15px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  },
  noData: {
    textAlign: 'center',
    padding: '20px',
    fontSize: '18px',
    color: '#999',
  },
};
