import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

const Resetpassword = () => {
  const navigate = useNavigate();

  // Form states
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state
  const [isSendingOtp, setIsSendingOtp] = useState(false); // OTP button disable state

  const password = localStorage.getItem('password');
  const a = localStorage.getItem('hello');

  useEffect(() => {
    if (!a) {
      navigate('/');
    }
  }, [a, navigate]);

  // Function to start the timer for 2 minutes (120 seconds)
  const startTimer = () => {
    setTimer(120);
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval); // Clear the timer when it reaches 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Function to send OTP using EmailJS
  const sendOtp = () => {
    const generated = Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit OTP
    setGeneratedOtp(generated);

    const templateParams = {
      email,
      otp: generated,
    };

    emailjs
      .send('service_7g19v7k', 'template_hgxpr1n', templateParams, 'wULa86zIb62si91Af')
      .then(
        () => {
          setShowOtpField(true);
          setIsSendingOtp(true); // Disable the button after OTP is sent
          startTimer(); // Start the timer for 2 minutes
          alert('OTP sent to your email.');
        },
        (error) => {
          setError('Failed to send OTP. Please try again.');
          console.error('EmailJS error:', error);
        }
      );
  };

  // OTP verification and form submission
  const handleOtpVerification = () => {
    if (otp !== generatedOtp?.toString()) {
      setError('Invalid OTP.');
      return;
    }

    setOtpVerified(true);
    setShowOtpField(false);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otpVerified) {
      setError('Please verify the OTP first.');
      return;
    }

    if (oldPassword !== password) {
      setError('Please enter correct old password.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/updatePassword', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password: newPassword,
          loggedstatus: 'completed',
        }),
      });

      if (response.ok) {
        setError('');
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          localStorage.removeItem('hello');
          navigate('/login');
        }, 3000);
      } else {
        setError('Failed to update password and/or logged status.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      console.error('Update error:', error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f5f5f5' }}>
      <form style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%', maxWidth: '400px' }} onSubmit={handleSubmit}>
        <h2 style={{ textAlign: 'center', color: '#333333' }}>Change Password</h2>

        {/* Email Field */}
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', marginBottom: '5px', color: '#333' }}>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={otpVerified}
            placeholder="Enter your email"
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px' }}
          />
        </div>

        {/* Send OTP Button */}
        {!otpVerified && (
          <button
            type="button"
            onClick={sendOtp}
            disabled={isSendingOtp || timer > 0}
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: timer > 0 ? '#ccc' : '#3f51b5',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: timer > 0 ? 'not-allowed' : 'pointer',
              fontSize: '16px',
              marginBottom: '15px',
            }}
          >
            {timer > 0 ? `Resend OTP in ${timer}s` : 'Send OTP'}
          </button>
        )}

        {/* OTP Field (only visible after OTP is sent) */}
        {showOtpField && (
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px', color: '#333' }}>Enter OTP</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              placeholder="Enter OTP sent to your email"
              style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px' }}
            />
            <button
              type="button"
              onClick={handleOtpVerification}
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: '#3f51b5',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '16px',
                marginTop: '10px',
              }}
            >
              Verify OTP
            </button>
          </div>
        )}

        {/* Remaining fields shown only after OTP verification */}
        {otpVerified && (
          <>
            {/* Old Password Field */}
            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px', color: '#333' }}>Old Password</label>
              <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
                placeholder="Enter Old Password"
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px' }}
              />
            </div>

            {/* New Password Field */}
            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px', color: '#333' }}>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                placeholder="Enter New Password"
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px' }}
              />
            </div>

            {/* Confirm New Password Field */}
            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px', color: '#333' }}>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm New Password"
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', fontSize: '14px' }}
              />
            </div>
          </>
        )}

        {/* Submit Button */}
        {otpVerified && (
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#3f51b5',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            Submit
          </button>
        )}

        {/* Success message */}
        {success && <p style={{ color: 'green', textAlign: 'center', marginTop: '10px' }}>Password updated successfully. Redirecting...</p>}

        {/* Error message */}
        {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</p>}
      </form>
    </div>
  );
};

export default Resetpassword;
