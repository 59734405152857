import React, { useState, useEffect } from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';
export default function AdminTeamleader({ onViewActivity }) {
    const [showModal, setShowModal] = useState(false);
    const [teamLeaders, setTeamLeaders] = useState([]);
    const [formData, setFormData] = useState({
        teamleadername: '',
        teamleaderphone: '',
        teamleaderref: '',
        teamleaderemail: '',
        emp_id: '',
        position: 'Team Leader' // Fixed position
    });
    const token=localStorage.getItem('adminToken')
    const navigate=useNavigate('')
    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('adminToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('adminToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
    useEffect(() => {
        fetch('https://mrv1.indianwelfarefoundation.org.in/teamleaders',
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => setTeamLeaders(data))
            .catch(error => console.error('Error fetching team leaders:', error));
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://mrv1.indianwelfarefoundation.org.in/teamleaders/count',
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(countData => {
                const emp_id = `TL${countData.count + 1}`; // Employee ID auto-generation for Team Leaders
                const finalFormData = formData.emp_id ? formData : { ...formData, emp_id };

                return fetch('https://mrv1.indianwelfarefoundation.org.in/teamleaders', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(finalFormData),
                });
            })
            .then(response => response.json())
            .then(newTeamLeader => {
                setTeamLeaders([...teamLeaders, newTeamLeader]);
                setShowModal(false);
            })
            .catch(error => console.error('Error adding team leader:', error));
    };

    return (
        <div className="admin-teamleader">
            <div className="top-bar">
                <button className="register-button" onClick={() => setShowModal(true)}>
                    Register Team Leader
                </button>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={() => setShowModal(false)}>&times;</span>
                        <h2>Register Team Leader</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="teamleadername"
                                placeholder="Name"
                                value={formData.teamleadername}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="teamleaderphone"
                                placeholder="Phone"
                                value={formData.teamleaderphone}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="teamleaderref"
                                placeholder="Reference"
                                value={formData.teamleaderref}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="email"
                                name="teamleaderemail"
                                placeholder="Email"
                                value={formData.teamleaderemail}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="emp_id"
                                placeholder="Employee ID "
                                value={formData.emp_id}
                                onChange={handleInputChange}
                                style={{ marginBottom: '10px' }}
                            />
                            <button type="submit">Register</button>
                        </form>
                    </div>
                </div>
            )}

            <div className="teamleader-table">
                <table>
                    <thead>
                        <tr style={{ backgroundColor: 'lightgreen' }}>
                            <th style={{ backgroundColor: 'green' }}>Name</th>
                            <th style={{ backgroundColor: 'green' }}>Phone</th>
                            <th style={{ backgroundColor: 'green' }}>Reference</th>
                            <th style={{ backgroundColor: 'green' }}>Email</th>
                            <th style={{ backgroundColor: 'green' }}>Activity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teamLeaders.map((leader, index) => (
                            <tr key={index}>
                                <td>{leader.teamleadername}</td>
                                <td>{leader.teamleaderphone}</td>
                                <td>{leader.teamleadereref}</td>
                                <td>{leader.teamleaderemail}</td>
                                <td>
                                    <button onClick={() => onViewActivity(leader)}>
                                        View Activity
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
