import logo from './logo.svg';
import './App.css';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Login from './Login';
import Admindashboard from './Admindashboard';
import AdminMarkAttendance from './AdminMarkAttendance';
import Adminattendance from './Adminattendance';
import AdminTeamleader from './AdminTeamleader';
import Adminemployees from './Adminemployees';
import Teamleaderdashboard from './Teamleaderdashboard';
import Employeedashboard from './Employeedashboard';
import Resetpassword from './Resetpassword';
function App() {
  return (
    <div >
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Login/>}></Route>
      <Route path='/Admindashboard' element={<Admindashboard/>}></Route>
      <Route path='/mark-attendance' element={<AdminMarkAttendance/>}></Route>
      <Route path='/attendance' element={<Adminattendance/>}></Route>
      <Route path='/team-leaders' element={<AdminTeamleader/>}></Route>
      <Route path='/team-leaders' element={<Adminemployees/>}></Route>
      <Route path='/Teamleaderdashboard' element={<Teamleaderdashboard/>}></Route>
      <Route path='/Employeedashboard' element={<Employeedashboard/>}></Route>
      <Route path='/Resetpassword' element={<Resetpassword/>}></Route>



    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
