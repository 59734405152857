import React, { useState, useEffect } from 'react';
import './Admin.css';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { useNavigate } from 'react-router-dom';

export default function EmployeeActivity({ employee, onBack }) {
    const [modalContent, setModalContent] = useState(null);
    const [attendanceData, setAttendanceData] = useState({ records: [], stats: {} });
    const [holidays, setHolidays] = useState([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const token = localStorage.getItem('adminToken') || 
    localStorage.getItem('teamLeaderToken') || 
    localStorage.getItem('employeeToken');


    const navigate=useNavigate('')
    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('employeeToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('employeeToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
 // Function to determine the range from the 21st of the previous month to the 20th of the current month
const getMonthYearRange = (currentDate) => {
    let startDate, endDate;
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // If current date is 21 or later, start from 21st of current month and end on 20th of next month
    if (currentDate.getDate() >= 21) {
        startDate = new Date(Date.UTC(currentYear, currentMonth, 21));  // Start on the 21st of this month
        endDate = new Date(Date.UTC(currentYear, currentMonth + 1, 20)); // End on the 20th of next month
    } else {
        // If current date is before 21, start from 21st of last month and end on 20th of this month
        startDate = new Date(Date.UTC(currentYear, currentMonth - 1, 21));  // Start on the 21st of the previous month
        endDate = new Date(Date.UTC(currentYear, currentMonth, 20));        // End on the 20th of the current month
    }

    return { startDate, endDate };
};



    const handlePreviousMonth = () => {
        const previousMonth = new Date(selectedDate);
        previousMonth.setMonth(previousMonth.getMonth() - 1); // Move one month back
        const { startDate, endDate } = getMonthYearRange(previousMonth);
        fetchAttendanceData(startDate, endDate);
    };

    const handleNextMonth = () => {
        const nextMonth = new Date(selectedDate);
        nextMonth.setMonth(nextMonth.getMonth() + 1); // Move one month forward
        const { startDate, endDate } = getMonthYearRange(nextMonth);
        fetchAttendanceData(startDate, endDate);
    };

    const getDisplayMonthYear = (startDate) => {
        const displayMonth = startDate.getDate() >= 21
            ? new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1)
            : new Date(startDate.getFullYear(), startDate.getMonth(), 1);

        return `${displayMonth.toLocaleString('default', { month: 'long' })}-${displayMonth.getFullYear()}`;
    };

    const formatDate = (date) => {
        return date instanceof Date && !isNaN(date) ? date.toISOString().split('T')[0] : '';
    };

    const isSunday = (date) => date.getDay() === 0;

    const isHoliday = (date) => {
        const formattedDate = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}`;
        return holidays.includes(formattedDate);
    };

    const fetchAttendanceData = (startDate, endDate) => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const monthYear = getDisplayMonthYear(startDate);
console.log('date range',formattedStartDate,formattedEndDate)
        fetch(`https://mrv1.indianwelfarefoundation.org.in/activity?emp_id=${employee.emp_id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const processedData = processAttendanceData(data, startDate, endDate);
                console.log(data)
                setAttendanceData(processedData);
                setSelectedMonthYear(monthYear);
                setSelectedDate(startDate); // Update the selected date when changing months
            })
            .catch(error => console.error('Error fetching attendance data:', error));
    };


    const fetchHolidays = () => {
        fetch('https://mrv1.indianwelfarefoundation.org.in/holidays',{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const holidayDates = data.map(holiday => holiday.date);
                setHolidays(holidayDates);
            })
            .catch(error => console.error('Error fetching holidays:', error));
    };

    const processAttendanceData = (data, startDate, endDate) => {
        const attendanceMap = {};
        const attendanceStats = { present: 0, absent: 0, leave: 0, workingDaysLeft: 0 };
        const today = new Date();
    
        data.forEach(record => {
            const { date, start_time, end_time } = record; // Make sure to include end_time
            const recordDate = new Date(date);
    
            if (isSunday(recordDate) || isHoliday(recordDate)) return; // Skip Sundays and Holidays
    
            let status = 'Absent'; // Default to 'Absent' for missing data
    
            // Mark as 'Present' if start_time exists
            if (start_time && start_time.toLowerCase() === 'leave') {
                status = 'Leave';
                attendanceStats.leave++;
            } else if (start_time) {
                status = 'Present';
                attendanceStats.present++;
            }
    
            attendanceMap[date] = { status, start_time, end_time }; // Store end_time
        });
    
        const allDates = [];
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            const formattedDate = d.toLocaleDateString('en-CA'); // YYYY-MM-DD format
    
            if (!isSunday(d) && !isHoliday(d)) { // Skip Sundays and Holidays
                allDates.push(formattedDate);
    
                if (!attendanceMap[formattedDate]) {
                    if (d <= today) {
                        // If the day is in the past and there's no record, mark it as Absent
                        attendanceStats.absent++;
                        attendanceMap[formattedDate] = { status: 'Absent' };
                    } else {
                        // If the day is in the future, mark as 'Working Days Left'
                        attendanceStats.workingDaysLeft++;
                        attendanceMap[formattedDate] = { status: 'Working Days Left' };
                    }
                }
            } else {
                allDates.push(formattedDate);
                attendanceMap[formattedDate] = { status: isSunday(d) ? 'Sunday' : 'Holiday' };
            }
        }
    
        return { records: allDates.map(date => ({ date, ...attendanceMap[date] || { status: 'Absent' } })), stats: attendanceStats };
    };
    

    useEffect(() => {
        const currentDate = new Date();
        const { startDate, endDate } = getMonthYearRange(currentDate);
        fetchHolidays();
        fetchAttendanceData(startDate, endDate);
    }, []);

    const handleCardClick = (content) => {
        setModalContent(content);
    };

    const closeModal = () => {
        setModalContent(null);
    };

    const attendanceChartData = {
        labels: ['Present Days', 'Absent Days', 'Leave Days', 'Working Days Left'],
        datasets: [
            {
                data: [
                    attendanceData.stats?.present || 0,
                    attendanceData.stats?.absent || 0,
                    attendanceData.stats?.leave || 0,
                    attendanceData.stats?.workingDaysLeft || 0
                ],
                backgroundColor: ['#28a745', '#dc3545', '#007bff', '#ffc107'],  // Colors for Present, Absent, Leave, Working Days Left
                hoverBackgroundColor: ['#218838', '#c82333', '#0062cc', '#e0a800'],  // Hover colors
            },
        ],
    };

    return (
        <div className="employee-activity">
            <button className="back-button" onClick={onBack}>Back to Employees</button>
            <h2>{employee.employeename}'s Activity</h2>
            <div className="card-group">
                <div className="card" onClick={() => handleCardClick('Personal Details')}>
                    Personal Details
                </div>
                <div className="card" >
                    <div className="attendance-card">
                        <div className="attendance-navigation">
                            <button className="left-arrow" onClick={handlePreviousMonth}>&lt;</button>
                            <div className="attendance-month">{selectedMonthYear}</div>
                            <button className="right-arrow" onClick={handleNextMonth}>&gt;</button>
                        </div>
                        <Pie className='pie' data={attendanceChartData} onClick={() => handleCardClick('Attendance')}/>
                    </div>
                </div>
                <div className="card" onClick={() => handleCardClick('Project Details')}>
                    Project Details
                </div>
                <div className="card" onClick={() => handleCardClick('Performance')}>
                    Performance
                </div>
            </div>

            {modalContent && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-button" onClick={closeModal}>&times;</span>
                        <h3>{modalContent}</h3>
                        {modalContent === 'Attendance' ? (
                            <div>
                                <h4>Attendance for {selectedMonthYear}</h4>
                                <table className="attendance-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendanceData.records && attendanceData.records.map((record, index) => (
                                            <tr key={index}>
                                                <td>{record.date}</td>
                                                {record.status === 'Sunday' || record.status === 'Holiday' ? (
                                                    <td colSpan={2} className="holiday-sunday">{record.status}</td>
                                                ) : (
                                                    <>
                                                        <td>{record.start_time || '-'}</td>
                                                        <td>{record.end_time || '-'}</td>
                                                        <td>{record.status === 'Present' ? 'Present' : record.status}</td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>Details about {modalContent} here...</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
