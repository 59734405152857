import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import './Admin.css'; // Import the external CSS file
import { useNavigate } from 'react-router-dom';

export default function Tasks() {
  const [employees, setEmployees] = useState([]);
  const [taskDescription, setTaskDescription] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [dateAssigned, setDateAssigned] = useState(new Date().toISOString().split('T')[0]);
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState('Today');
const token = localStorage.getItem('teamLeaderToken')
  const teamemp_id = localStorage.getItem('teamid');
  const navigate=useNavigate('')
  useEffect(() => {
      const checkToken = async () => {
  
  
        if (!token) {
          navigate('/');
          return;
        }
  
        try {
          const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
            method: 'POST',
            headers: {
  
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
  
          const data = await response.json();
  
          if (data.isValid) {
            
          } else {
            localStorage.removeItem('teamLeaderToken')
            
            navigate('/');
          }
        } catch (error) {
          localStorage.removeItem('teamLeaderToken')
  
         
          navigate('/');
        }
      };
  
      checkToken();
    }, [navigate]);
  useEffect(() => {
    fetchEmployees();
    fetchTasks();
  }, [activeTab]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('https://mrv1.indianwelfarefoundation.org.in/employees',{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      
      const employeeOptions = response.data.map((employee) => ({
        value: employee.emp_id,
        label: employee.employeename,
      }));

      setEmployees(employeeOptions);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const endpoint = activeTab === 'Today' ? '/tasks/today' : '/tasks/previous';
      const response = await axios.post(`https://mrv1.indianwelfarefoundation.org.in${endpoint}`, { teamemp_id },{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      console.log(response)
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const assignTask = async () => {
    try {
      await axios.post('https://mrv1.indianwelfarefoundation.org.in/tasks/assign', {
        emp_id: selectedEmployees.map((employee) => employee.value), // Ensure emp_id is sent as an array
        task_description: taskDescription,
        date_assigned: dateAssigned,
        teamemp_id,
      });
      fetchTasks();
      setTaskDescription('');
      setSelectedEmployees([]);
    } catch (error) {
      console.error('Error assigning task:', error);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedEmployees(selectedOptions || []);
  };

  const handleSelectAll = () => {
    setSelectedEmployees(employees);
  };

  return (
    <div className="taskContainer">
      <div className="taskForm">
        <h2>Assign New Task</h2>
        <div className="selectContainer">
          <div className="selectEmployeeContainer">
            <label htmlFor="selectEmployees">Select Employees</label>
            <Select
              id="selectEmployees"
              value={selectedEmployees}
              onChange={handleSelectChange}
              options={employees}
              isMulti
              placeholder="Select Employees"
              className="selectEmployee"
              closeMenuOnSelect={false}
              isDisabled={employees.length === 0}
            />
          </div>
          <div className="selectAllContainer">
            <button
              onClick={handleSelectAll}
              className="selectAllButton"
              disabled={employees.length === 0}
            >
              Select All
            </button>
          </div>
        </div>
        <textarea
          value={taskDescription}
          onChange={(e) => setTaskDescription(e.target.value)}
          placeholder="Task Description"
          className="textArea"
        ></textarea>
        <input
          type="date"
          value={dateAssigned}
          onChange={(e) => setDateAssigned(e.target.value)}
          className="datePicker"
        />
        <button onClick={assignTask} className="assignButton">
          Assign Task
        </button>
      </div>

      <div className="taskOverview">
        <div className="tabs">
          <button
            className={activeTab === 'Today' ? 'activeTab' : 'tab'}
            onClick={() => setActiveTab('Today')}
          >
            Today's Tasks
          </button>
          <button
            className={activeTab === 'Previous' ? 'activeTab' : 'tab'}
            onClick={() => setActiveTab('Previous')}
          >
            Previous Tasks
          </button>
        </div>
        <div className="taskList">
          {tasks.length === 0 ? (
            <div>No tasks found for {activeTab.toLowerCase()}.</div>
          ) : (
            tasks.map((task) => (
              <div key={task.task_id} className="taskItem">
                <div>
                  <strong>{task.employee_names}</strong>: {task.task_description} (Assigned on: {task.date_assigned})
                </div>
                <div>Status: {task.status}</div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
