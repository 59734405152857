import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

export default function Reports() {
  const [activeTab, setActiveTab] = useState('daily-attendance');
  const [dailyAttendance, setDailyAttendance] = useState([]);
  const [monthlyAttendance, setMonthlyAttendance] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Updated initialization
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const token=localStorage.getItem('adminToken')
  // Helper to convert 12-hour to 24-hour format
  const navigate=useNavigate('')
    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('adminToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('adminToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
  const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') hours = '00';
    if (modifier === 'PM') hours = parseInt(hours, 10) + 12;
    return `${hours}:${minutes}`;
  };

  // Function to calculate worked hours
  const calculateWorkedHours = (start, end) => {
    if (start === 'Still not logged in' || start === 'leave') return '-';
    const currentDate = new Date().toISOString().split('T')[0];
    const startTime = new Date(`${currentDate}T${convertTo24Hour(start)}`);
    const endTime = end ? new Date(`${currentDate}T${convertTo24Hour(end)}`) : new Date();
    if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) return 'Invalid Time';
    const diff = Math.abs(endTime - startTime);
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m`;
  };

  // Function to determine Late Login status
  const isLateLogin = (start) => {
    if (start === 'Still not logged in') return 'Yes';
    if (start === 'leave') return 'Leave';

    const loginTime = new Date(`1970-01-01T${convertTo24Hour(start)}:00`);
    const lateThreshold = new Date('1970-01-01T09:00:00'); // 9:00 AM

    return loginTime > lateThreshold ? 'Yes' : 'No';
  };

  const calculateSundays = (startDate, endDate) => {
    let count = 0;
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      if (currentDate.getDay() === 0) count++; // Sunday
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return count;
  };

  const fetchHolidays = async (startOfMonth, endOfMonth) => {
    try {
      const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/holidays',{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }); // Replace with your holidays endpoint
      const data = await response.json();

      const parseHolidayDate = (holidayString) => {
        const [day, month] = holidayString.split('-');
        const currentYear = startOfMonth.getFullYear();
        return new Date(`${currentYear}-${month}-${day}`);
      };

      const filteredHolidays = data.filter((holiday) => {
        const holidayDate = parseHolidayDate(holiday.date);
        return holidayDate >= startOfMonth && holidayDate <= endOfMonth;
      });

      setHolidays(filteredHolidays);
      return filteredHolidays.length;
    } catch (error) {
      console.error('Error fetching holidays:', error);
      return 0;
    }
  };

  // Fetch Daily Attendance when the date changes
  const fetchDailyAttendance = async (date) => {
    try {
      const response = await fetch(`https://mrv1.indianwelfarefoundation.org.in/reportdaily?date=${date}`,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      const data = await response.json();
console.log(data)
      const filteredAttendance = data.employees.map((record) => ({
        ...record,
        start_time: record.start_time || 'Still not logged in',
        end_time: record.end_time || '',
        late_login: isLateLogin(record.start_time || 'Still not logged in'), // Determine late login here
      }));

      setDailyAttendance(filteredAttendance);
    } catch (error) {
      console.error('Error fetching daily attendance:', error);
    }
  };

  const fetchMonthlyAttendance = async (selectedMonth) => {
    try {
      const month = selectedMonth.getMonth();
      const year = selectedMonth.getFullYear();
      let startOfMonth, endOfMonth;
  
      if (selectedMonth.getDate() >= 22) {
        startOfMonth = new Date(year, month, 22);
        endOfMonth = new Date(year, month + 1, 21);
      } else {
        startOfMonth = new Date(year, month - 1, 22);
        endOfMonth = new Date(year, month, 21);
      }
  
      const formattedStartDate = startOfMonth.toISOString().split('T')[0];
      const formattedEndDate = endOfMonth.toISOString().split('T')[0];
  
      const response = await fetch(`https://mrv1.indianwelfarefoundation.org.in/monthreport?start_date=${formattedStartDate}&end_date=${formattedEndDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
  
      // Calculate holidays and Sundays
      const holidaysCount = await fetchHolidays(startOfMonth, endOfMonth);
      const sundaysCount = calculateSundays(startOfMonth, endOfMonth);
  
      const totalPossibleWorkingDays = (endOfMonth - startOfMonth) / (1000 * 60 * 60 * 24) + 1;
      const actualCompanyWorkingDays = totalPossibleWorkingDays - holidaysCount - sundaysCount;
  
      // Reorder the properties to put 'teamleadername' at the top
      const processedData = data.map((record) => {
        const absentDays = actualCompanyWorkingDays - record.actual_worked_days - record.leaves_taken;
  
        return {
         
          ...record, // Spread the rest of the object properties
          holidays: holidaysCount,
          sundays: sundaysCount,
          total_working_days: totalPossibleWorkingDays,
          actual_company_working_days: actualCompanyWorkingDays,
          absent_days: absentDays < 0 ? 0 : absentDays, // If absent days are negative, set to 0
        };
      });
  
      setMonthlyAttendance(processedData);
    } catch (error) {
      console.error('Error fetching monthly attendance:', error);
    }
  };
  

  const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  // Trigger attendance fetching when the active tab or date/month changes
  useEffect(() => {
    if (activeTab === 'daily-attendance') {
      fetchDailyAttendance(selectedDate); // Fetch daily attendance based on selected date
    } else if (activeTab === 'monthly-attendance') {
      fetchMonthlyAttendance(selectedMonth); // Fetch monthly attendance based on selected month
    }
  }, [activeTab, selectedDate, selectedMonth]); // Dependency array now includes selectedDate and selectedMonth

  // Rendering Daily Attendance table
  const renderDailyAttendance = () => (
    <div>
      <h3>Daily Attendance</h3>
      <input
        type="date"
        value={selectedDate} // Updated with the selectedDate
        onChange={(e) => setSelectedDate(e.target.value)} // Set the date when user changes it
        style={{ marginBottom: '10px', width: '150px' }}
      />
      <button className="profile"
        onClick={() => exportToExcel(dailyAttendance, `${selectedDate} Daily_Attendance`)}
        style={{ float: 'right', marginBottom: '10px',cursor:'pointer' }}
      >
        Export to Excel
      </button>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Work Time</th>
            <th>End Work Time</th>
            <th>Worked Hours</th>
            <th>Late Login</th>
          </tr>
        </thead>
        <tbody>
          {dailyAttendance.map((record, index) => (
            <tr key={index}>
              <td>{record.employeename}</td>
              <td style={{ color: record.start_time === 'Still not logged in' ? 'red' : 'green' }}>
                {record.start_time}
              </td>
              <td>{record.end_time || 'Still not logged out'}</td>
              <td>{calculateWorkedHours(record.start_time, record.end_time)}</td>
              <td>{record.late_login}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );


  const renderMonthlyAttendance = () => {
    const startOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1, 22);
    const endOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 21);

    const formattedStartDate = startOfMonth.toISOString().split('T')[0];
    const formattedEndDate = endOfMonth.toISOString().split('T')[0];

    return (
      <div>
        <h3>
          Monthly Attendance ({formattedStartDate} - {formattedEndDate})
        </h3>
        <input
          type="month"
          value={`${selectedMonth.getFullYear()}-${String(selectedMonth.getMonth() + 1).padStart(2, '0')}`}
          onChange={(e) => setSelectedMonth(new Date(e.target.value + '-01'))}
          style={{ marginBottom: '10px' }}
        />
        <button  className="profile" onClick={() => exportToExcel(monthlyAttendance, ` ${formattedStartDate} - ${formattedEndDate} Monthly_Attendance`)} style={{ float: 'right', marginBottom: '10px',cursor:'pointer' }}>
          Export to Excel
        </button>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Total Working Days</th>
              <th>Holidays</th>
              <th>Sundays</th>
              <th>Actual Company Working Days</th>
              <th>Actual Worked Days</th>
              <th>Leaves Taken</th>
              <th>Absent Days</th>
            </tr>
          </thead>
          <tbody>
            {monthlyAttendance.map((record, index) => (
              <tr key={index}>
                <td>{record.employeename }</td>
                <td>{record.total_working_days}</td>
                <td>{record.holidays}</td>
                <td>{record.sundays}</td>
                <td>{record.actual_company_working_days}</td>
                <td>{record.actual_worked_days}</td>
                <td>{record.leaves_taken}</td>
                <td>{record.absent_days}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="reports-container">
     
      <div className="report-buttons">
        <button onClick={() => setActiveTab('daily-attendance')}>Daily Attendance</button>
        <button onClick={() => setActiveTab('monthly-attendance')}>Monthly Attendance</button>
        <button onClick={() => setActiveTab('payslips')}>Payslips</button>
      </div>


      <div className="report-content">
        {activeTab === 'daily-attendance' && renderDailyAttendance()}
        {activeTab === 'monthly-attendance' && renderMonthlyAttendance()}
        {activeTab === 'payslips' && <div>Payslips section coming soon...</div>}
      </div>
    </div>
  );
}
