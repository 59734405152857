import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function EmpTask() {
    const [todayTasks, setTodayTasks] = useState([]);
    const [previousTasks, setPreviousTasks] = useState([]);
    const [showTodayTasks, setShowTodayTasks] = useState(true);
    const emp_id = 'MRV0005-E1';  // Example emp_id, replace with actual value
    const token = localStorage.getItem('employeeToken');
    const navigate=useNavigate('')
    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('employeeToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('employeeToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
    useEffect(() => {
        fetch(`https://mrv1.indianwelfarefoundation.org.in/tasks/${emp_id}`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const today = new Date().toISOString().split('T')[0];

                const todayTasks = data.filter(task => task.date_assigned === today);
                const previousTasks = data.filter(task => task.date_assigned !== today);

                setTodayTasks(todayTasks);
                setPreviousTasks(previousTasks);
            })
            .catch(error => console.error('Error fetching tasks:', error));
    }, [emp_id]);

    return (
        <div className="task-container">
            <div className="task-options">
                <button 
                    className={showTodayTasks ? 'active' : ''} 
                    onClick={() => setShowTodayTasks(true)}>
                    Today's Tasks
                </button>
                <button 
                    className={!showTodayTasks ? 'active' : ''} 
                    onClick={() => setShowTodayTasks(false)}>
                    Previous Tasks
                </button>
            </div>

            <div className="tasks">
                {showTodayTasks ? (
                    todayTasks.length > 0 ? (
                        todayTasks.map(task => (
                            <div key={task.task_id} className="task-card">
                                <h3>{task.date_assigned}</h3>
                                <p>{task.task_description}</p>
                                <button>Complete</button>
                            </div>
                        ))
                    ) : (
                        <p>No tasks for today.</p>
                    )
                ) : (
                    previousTasks.length > 0 ? (
                        previousTasks.map(task => (
                            <div key={task.task_id} className="task-card">
                                <h3>{task.date_assigned}</h3>
                                <p>{task.task_description}</p>
                                <button>Complete</button>
                            </div>
                        ))
                    ) : (
                        <p>No previous tasks.</p>
                    )
                )}
            </div>
        </div>
    );
}
