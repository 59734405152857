import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Employeemarkattendance from './Employeemarkattandance';
import Employeeattendance from './Employeeattendance';
import Emptask from './Emptask';
import Leave from './Leave';

export default function Employeedashboard() {
    const [activeTab, setActiveTab] = useState('dashboard');
    const [currentTime, setCurrentTime] = useState(new Date());
    const [attendanceData, setAttendanceData] = useState({
        actualWorkingDays: 0,
        totalWorkedDays: 0,
        leavesTaken: 0,
        absentDays: 0
    });
    const [upcomingHoliday, setUpcomingHoliday] = useState(null);
    const [holidays, setHolidays] = useState([]);
    const [projectDetails, setProjectDetails] = useState({
        projectName: 'No active project',
        details: 'N/A'
    });
    const navigate = useNavigate();
    const token = localStorage.getItem('employeeToken');
    const emp_id = localStorage.getItem('employeeid');
    const adminname = localStorage.getItem('employeename');
    const [selectedMonthYear, setSelectedMonthYear] = useState('');

    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('employeeToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('employeeToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    const getDisplayMonthYear = (startDate) => {
        const displayMonth = startDate.getDate() >= 20
            ? new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1)
            : new Date(startDate.getFullYear(), startDate.getMonth(), 1);

        return `${displayMonth.toLocaleString('default', { month: 'long' })}-${displayMonth.getFullYear()}`;
    };
    useEffect(() => {
        const now = new Date();
        let startDate, endDate;
        
        // Correctly initialize startDate before passing to getDisplayMonthYear
        if (now.getDate() >= 21) {
            startDate = new Date(now.getFullYear(), now.getMonth(), 22);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 21); // 20th of the next month
        } else {
            startDate = new Date(now.getFullYear(), now.getMonth() - 1, 22);
            endDate = new Date(now.getFullYear(), now.getMonth(), 21); // 20th of this month
        }
    
        // Now it's safe to call getDisplayMonthYear
        const monthYear = getDisplayMonthYear(startDate);
    
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
    
        // Fetch holidays
        fetch('https://mrv1.indianwelfarefoundation.org.in/holidays',{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setHolidays(data);
                const today = new Date();
                const upcoming = data
                    .filter(holiday => {
                        const [holidayDay, holidayMonth] = holiday.date.split('-');
                        const holidayDate = new Date(`${holidayMonth} ${holidayDay}, ${today.getFullYear()}`);
                        return holidayDate > today;
                    })
                    .sort((a, b) => {
                        const [dayA, monthA] = a.date.split('-');
                        const [dayB, monthB] = b.date.split('-');
                        return new Date(`${monthA} ${dayA}, ${today.getFullYear()}`) - new Date(`${monthB} ${dayB}, ${today.getFullYear()}`);
                    })[0];
                setSelectedMonthYear(monthYear);
                setUpcomingHoliday(upcoming);
            })
            .catch(error => console.error('Error fetching holiday data:', error));
    
        // Fetch attendance data
        fetch(`https://mrv1.indianwelfarefoundation.org.in/activity?emp_id=${emp_id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                let totalWorkedDays = 0;
                let leavesTaken = 0;
                let absentDays = 0;
    
                // Get all Sundays and holidays within the date range
                const sundays = getSundaysInRange(startDate, endDate);
                const holidayDates = holidays.map(h => h.date);
    
                // Total possible working days excluding Sundays and holidays
                const totalDays = getWorkingDaysInRange(startDate, endDate, sundays, holidayDates);
    
                data.forEach(record => {
                    const attendanceDate = new Date(record.date).toISOString().split('T')[0];
    
                    if (record.start_time === 'leave' && record.end_time === 'leave') {
                        leavesTaken += 1;
                    } else if (record.start_time && record.end_time) {
                        totalWorkedDays += 1;
                    } else if (!record.start_time && !record.end_time) {
                        absentDays += 1;
                    }
                });
    
                const absentDaysCalculated = totalDays - totalWorkedDays - leavesTaken;
    
                setAttendanceData({
                    actualWorkingDays: totalDays,
                    totalWorkedDays,
                    leavesTaken,
                    absentDays: absentDaysCalculated > 0 ? absentDaysCalculated : 0
                });
            })
            .catch(error => console.error('Error fetching attendance data:', error));
    
        // Update time every second
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
    
        return () => clearInterval(intervalId);
    }, [emp_id, holidays]);
    
    

    const getSundaysInRange = (startDate, endDate) => {
        const sundays = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) { // Sunday is 0
                sundays.push(currentDate.toISOString().split('T')[0]);
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return sundays;
    };

    const getWorkingDaysInRange = (startDate, endDate, sundays, holidays) => {
        let workingDays = 0;
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const currentDateString = currentDate.toISOString().split('T')[0];
            if (!sundays.includes(currentDateString) && !holidays.includes(currentDateString)) {
                workingDays += 1;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return workingDays;
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'dashboard':
                return (
                    <div style={{ padding: '20px' }}>
                        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                {currentTime.toLocaleDateString('en-US', { weekday: 'long' })}
                            </div>
                            <div style={{ fontSize: '18px' }}>
                                {currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                            {/* Attendance */}
                            <div style={{ border: '1px solid #ccc', padding: '20px', width: '30%', marginBottom: '20px' }}>
                            <div className="attendance-month">{selectedMonthYear}</div>
                                <p>Actual Working Days: {attendanceData.actualWorkingDays}</p>
                                <p>Total Worked Days: {attendanceData.totalWorkedDays}</p>
                                <p>Leaves Taken: {attendanceData.leavesTaken}</p>
                                <p>Absent Days: {attendanceData.absentDays}</p>
                            </div>

                            {/* Upcoming Holiday */}
                            <div style={{ border: '1px solid #ccc', padding: '20px', width: '30%', marginBottom: '20px' }}>
                                <h3>Upcoming Holiday</h3>
                                {upcomingHoliday ? (
                                    <>
                                        <p>{upcomingHoliday.date}</p>
                                        <p>{upcomingHoliday.name}</p>
                                    </>
                                ) : (
                                    <p>No upcoming holidays</p>
                                )}
                            </div>

                            {/* Project Details */}
                            <div style={{ border: '1px solid #ccc', padding: '20px', width: '30%', marginBottom: '20px' }}>
                                <h3>Project Details</h3>
                                <p>Project Name: {projectDetails.projectName}</p>
                                <p>Details: {projectDetails.details}</p>
                            </div>
                        </div>
                    </div>
                );
            case 'mark-attendance':
                return <Employeemarkattendance />;
            case 'attendance':
                return <Employeeattendance />;
            case 'Leave':
                return <Leave />;
            case 'Emptask':
                return <Emptask />;
            default:
                return null;
        }
    };
    const updateEmployeeStatus = async (emp_id, status) => {
        try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/updateEmployeeStatus', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    emp_id: emp_id,
                    activestatus: status,
                }),
            });
    
            if (response.ok) {
                console.log(`Employee status updated to: ${status}`);
            } else {
                console.error('Failed to update employee status.');
            }
        } catch (error) {
            console.error('Error while updating employee status:', error);
        }
    };
    
    const logOut = () => {
        const empId = localStorage.getItem('employeeid');

        // Update the status to 'inactive' when the user logs out
        updateEmployeeStatus(empId, 'inactive');
    
        // Remove employee-related data from local storage
        localStorage.removeItem('employeeToken');
        localStorage.removeItem('employeeid');
        localStorage.removeItem('employeename');
        navigate('/');
    };

    return (
        <div className="admin-dashboard">
            <aside className="sidebar">
                <div className="logo">{adminname}</div>
                <nav className="nav">
                    <ul>
                        <li
                            className={activeTab === 'dashboard' ? 'active' : ''}
                            onClick={() => setActiveTab('dashboard')}
                        >
                            Dashboard
                        </li>
                        <li
                            className={activeTab === 'mark-attendance' ? 'active' : ''}
                            onClick={() => setActiveTab('mark-attendance')}
                        >
                            Mark Attendance
                        </li>
                        <li
                            className={activeTab === 'attendance' ? 'active' : ''}
                            onClick={() => setActiveTab('attendance')}
                        >
                            Attendance
                        </li>
                        <li
                            className={activeTab === 'Emptask' ? 'active' : ''}
                            onClick={() => setActiveTab('Emptask')}
                        >
                            Tasks
                        </li>
                        <li
                            className={activeTab === 'Leave' ? 'active' : ''}
                            onClick={() => setActiveTab('Leave')}
                        >
                            Leave
                        </li>
                    </ul>
                </nav>
            </aside>
            <div className="main-content">
                <header className="header">
                    <h2>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
                    <div className="profile" style={{cursor:'pointer'}} onClick={logOut}>Logout</div>
                </header>
                <section className="content">{renderContent()}</section>
            </div>
        </div>
    );
}
