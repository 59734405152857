import React, { useEffect, useState } from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';
import AdminMarkAttendance from './AdminMarkAttendance';
import Adminattendance from './Adminattendance';
import AdminTeamleader from './AdminTeamleader';
import Adminemployees from './Adminemployees';
import EmployeeActivity from './EmployeeActivity';
import TeamleaderActivity from './TeamleaderActivity';
import ManageLeave from './Manageleave';
import Events from './Events';
import Reports from './Reports';

export default function AdminDashboard() {
    const [activeTab, setActiveTab] = useState('dashboard');
    const [teamLeaderCount, setTeamLeaderCount] = useState(0);
    const [employeeCount, setEmployeeCount] = useState(0);
    const [currentTime, setCurrentTime] = useState(new Date());
    const navigate = useNavigate();
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedTeamleader, setSelectedTeamleader] = useState(null);
    const [employeeAttendance, setEmployeeAttendance] = useState([]);
    const [teamLeaderAttendance, setTeamLeaderAttendance] = useState([]);
    const token = localStorage.getItem('adminToken');

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    useEffect(() => {
        const fetchAttendanceData = async () => {
            try {
                const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/adminteamemp',{
                  headers: {
                      'Authorization': `Bearer ${token}`
                  }
              });
                const data = await response.json();

                const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

                // Filter attendance records for today's date
                const filterAttendanceByToday = (records) => {
                    return records.map((record) => ({
                        ...record,
                        start_time: record.start_time ? record.start_time : 'Still not logged in',
                        end_time: record.end_time ? record.end_time : '',
                    })).filter((record) => !record.date || record.date === today);
                };

                setEmployeeAttendance(filterAttendanceByToday(data.employees));
                setTeamLeaderAttendance(filterAttendanceByToday(data.teamLeaders));
            } catch (error) {
                console.error('Failed to fetch attendance data:', error);
            }
        };

        fetchAttendanceData();
    }, []);
    useEffect(() => {
      const checkToken = async () => {
  
  
        if (!token) {
          navigate('/');
          return;
        }
  
        try {
          const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
            method: 'POST',
            headers: {
  
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
  
          const data = await response.json();
  
          if (data.isValid) {
            
          } else {
            localStorage.removeItem('adminToken')
            
            navigate('/');
          }
        } catch (error) {
          localStorage.removeItem('adminToken')
  
         
          navigate('/');
        }
      };
  
      checkToken();
    }, [navigate]);
    useEffect(() => {
        // Fetch team leaders count
        fetch('https://mrv1.indianwelfarefoundation.org.in/countteam',{
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
            .then(response => response.json())
            .then(data => setTeamLeaderCount(data.count))
            .catch(error => console.error('Error fetching team leader count:', error));

        // Fetch employee count
        fetch('https://mrv1.indianwelfarefoundation.org.in/countemp',{
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
            .then(response => response.json())
            .then(data => setEmployeeCount(data.count))
            .catch(error => console.error('Error fetching employee count:', error));

        // Update time every second
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    
    // Function to calculate the difference in hours and minutes
    const convertTo24Hour = (time12h) => {
      const [time, modifier] = time12h.split(' ');
      let [hours, minutes] = time.split(':');
  
      if (hours === '12') {
          hours = '00';
      }
  
      if (modifier === 'PM') {
          hours = parseInt(hours, 10) + 12;
      }
  
      return `${hours}:${minutes}`;
  };
  
  // Function to calculate the difference in hours and minutes
  const calculateWorkedHours = (start, end) => {
      if (start === 'Still not logged in' || start === 'leave' ) return '-';
  
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  
      // Convert start and end times to 24-hour format
      const startTime = new Date(`${currentDate}T${convertTo24Hour(start)}`);
      const endTime = end ? new Date(`${currentDate}T${convertTo24Hour(end)}`) : new Date(); // Use current time if still logged in
  
      if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
          return 'Invalid Time';  // Handle invalid time entries
      }
  
      const diff = Math.abs(endTime - startTime); // Difference in milliseconds
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  
      return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m`;
  };
  const renderContent = () => {
      switch (activeTab) {
          case 'dashboard':
              return (
                  <div className="dashboard-content">
                      <div className="time-container">
                          <div className="day">
                              {new Date().toLocaleDateString('en-US', { weekday: 'long' })}
                          </div>
                          <div className="time">
                              {new Date().toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                              })}
                          </div>
                      </div>
                      <div className="stats-container">
                          <div className="stat-box team-leaders">
                              <h3>Team Leaders</h3>
                              <p>{teamLeaderAttendance.length}</p>
                          </div>
                          <div className="stat-box employees">
                              <h3>Employees</h3>
                              <p>{employeeAttendance.length}</p>
                          </div>
                      </div>
  
                      <div className="attendance-container">
                          {/* Employee Attendance Table */}
                          <div className="attendance-table">
                              <h3>Employee Attendance ({today})</h3>
                              <table>
                                  <thead>
                                      <tr>
                                          <th>Employee Name</th>
                                          <th>Login Time</th>
                                          <th>Logout Time</th>
                                          <th>Worked Hours</th>
                                          <th>Employee status</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {employeeAttendance.length > 0 ? (
                                          employeeAttendance.map((record, index) => (
                                              <tr key={index}>
                                                  <td>{record.employeename}</td>
                                                  <td style={{ color: record.start_time === 'Still not logged in' ? 'red' : 'green' }}>{record.start_time}</td>
                                                  <td className={record.end_time ? "green-text" : "red-text"}>
                                                      {record.end_time || 'Still not logged out'}
                                                  </td>
                                                  <td>{calculateWorkedHours(record.start_time, record.end_time)}</td>
                                                  <td>{record.activestatus}</td>
                                              </tr>
                                          ))
                                      ) : (
                                          <tr>
                                              <td colSpan="4">No data available for today</td>
                                          </tr>
                                      )}
                                  </tbody>
                              </table>
                          </div>
  
                          {/* Team Leader Attendance Table */}
                          <div className="attendance-table">
                              <h3>Team Leader Attendance ({today})</h3>
                              <table>
                                  <thead>
                                      <tr>
                                          <th>Team Leader Name</th>
                                          <th>Login Time</th>
                                          <th>Logout Time</th>
                                          <th>Worked Hours</th>

                                      </tr>
                                  </thead>
                                  <tbody>
                                      {teamLeaderAttendance.length > 0 ? (
                                          teamLeaderAttendance.map((record, index) => (
                                              <tr key={index}>
                                                  <td>{record.teamleadername}</td>
                                                  <td style={{ color: record.start_time === 'Still not logged in' ? 'red' : 'green' }}>{record.start_time}</td>
                                                  <td className={record.end_time ? "green-text" : "red-text"}>
                                                      {record.end_time || 'Still not logged out'}
                                                  </td>
                                                  <td>{calculateWorkedHours(record.start_time, record.end_time)}</td>
                                              </tr>
                                          ))
                                      ) : (
                                          <tr>
                                              <td colSpan="4">No data available for today</td>
                                          </tr>
                                      )}
                                  </tbody>
                              </table>
                            </div>
                        </div>
                    </div>
                );
            case 'mark-attendance':
                return <AdminMarkAttendance />;
            case 'attendance':
                return <Adminattendance />;
            case 'leave-management':
                return <ManageLeave />;
            case 'Reports':
                return <Reports />;
            case 'Events':
                return <Events />;
            case 'team-leaders':
                return (
                    <AdminTeamleader
                        onViewActivity={(leader) => {
                            setSelectedTeamleader(leader);
                            setActiveTab('teamleaders-activity');
                        }}
                    />
                );
            case 'teamleaders-activity':
                return (
                    <TeamleaderActivity
                        leader={selectedTeamleader}
                        onBack={() => setActiveTab('team-leaders')}
                    />
                );
            case 'employees':
                return (
                    <Adminemployees
                        onViewActivity={(employee) => {
                            setSelectedEmployee(employee);
                            setActiveTab('employee-activity');
                        }}
                    />
                );
            case 'employee-activity':
                return (
                    <EmployeeActivity
                        employee={selectedEmployee}
                        onBack={() => setActiveTab('employees')}
                    />
                );
            default:
                return null;
        }
    };

    const adminname = localStorage.getItem('adminname');
    const logOut = () => {
        localStorage.removeItem('adminToken');
        navigate('/');
    };

    return (
        <div className="admin-dashboard">
            <aside className="sidebar">
                <div className="logo">{adminname}</div>
                <nav className="nav">
                    <ul>
                        <li
                            className={activeTab === 'dashboard' ? 'active' : ''}
                            onClick={() => setActiveTab('dashboard')}
                        >
                            Dashboard
                        </li>
                        <li
                            className={activeTab === 'mark-attendance' ? 'active' : ''}
                            onClick={() => setActiveTab('mark-attendance')}
                        >
                            Mark Attendance
                        </li>
                        <li
                            className={activeTab === 'attendance' ? 'active' : ''}
                            onClick={() => setActiveTab('attendance')}
                        >
                            Attendance
                        </li>
                        <li
                            className={activeTab === 'leave-management' ? 'active' : ''}
                            onClick={() => setActiveTab('leave-management')}
                        >
                            Manage Leave
                        </li>
                        <li
                            className={activeTab === 'team-leaders' ? 'active' : ''}
                            onClick={() => setActiveTab('team-leaders')}
                        >
                            Team Leaders
                        </li>
                        <li
                            className={activeTab === 'employees' ? 'active' : ''}
                            onClick={() => setActiveTab('employees')}
                        >
                            Employees
                        </li>
                        <li
                            className={activeTab === 'Reports' ? 'active' : ''}
                            onClick={() => setActiveTab('Reports')}
                        >
                            Reports
                        </li>
                        <li
                            className={activeTab === 'Events' ? 'active' : ''}
                            onClick={() => setActiveTab('Events')}
                        >
                            Events
                        </li>
                    </ul>
                </nav>
            </aside>
            <div className="main-content">
                <header className="header">
                    <h2>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
                    <div className="profile" style={{cursor:'pointer'}} onClick={logOut}>Logout</div>
                </header>
                <section className="content">{renderContent()}</section>
            </div>
        </div>
    );
}
