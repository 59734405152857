import React, { useState, useEffect } from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';

export default function AdminAttendance() {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [attendanceData, setAttendanceData] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const navigate=useNavigate('')
    const emp_id = localStorage.getItem('adminid');
const token=localStorage.getItem('adminToken')
    useEffect(() => {
        fetchAttendanceData();
        fetchHolidays();
    }, [selectedYear, selectedMonth]);

    const fetchAttendanceData = () => {
        const monthsToSend = [];
        
        // Add the current month
        monthsToSend.push(selectedMonth);
        
        // Add the previous month
        const previousMonth = selectedMonth === 1 ? 12 : selectedMonth - 1;
        monthsToSend.push(previousMonth);
        
        // Adjust the year if the current month is January
        const yearToSend = selectedMonth === 1 ? selectedYear - 1 : selectedYear;

        const requests = monthsToSend.map(month => 
            fetch(`https://mrv1.indianwelfarefoundation.org.in/admin/attendance?emp_id=${emp_id}&year=${yearToSend}&month=${month}`,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        );

        Promise.all(requests)
            .then(responses => Promise.all(responses.map(res => res.json())))
            .then(data => {
                const combinedData = data.flat(); // Flatten the array
                console.log('Fetched Attendance Data:', combinedData);
                setAttendanceData(combinedData);
            })
            .catch((error) => console.error('Error fetching attendance data:', error));
    };

    const formatHolidayDate = (dateStr) => {
        const [day, monthName] = dateStr.split('-');
        const monthMap = {
            Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
            Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
        };
        const monthIndex = monthMap[monthName];
        const year = selectedYear;
        const holidayDate = new Date(Date.UTC(year, monthIndex, parseInt(day)));
        return holidayDate.toISOString().split('T')[0];
    };

    const fetchHolidays = () => {
        fetch(`https://mrv1.indianwelfarefoundation.org.in/holidays`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                const filteredHolidays = data.map(holiday => ({
                    name: holiday.name,
                    holiday_date: formatHolidayDate(holiday.date)
                }));
                setHolidays(filteredHolidays);
            })
            .catch((error) => console.error('Error fetching holidays:', error));
    };

    const isSunday = (date) => {
        const day = new Date(date).getUTCDay();
        return day === 0;
    };

    const getHolidayName = (date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        const holiday = holidays.find(holiday => holiday.holiday_date === formattedDate);
        return holiday ? holiday.name : null;
    };

    const generateDateRange = (year, month) => {
        const startDate = new Date(Date.UTC(year, month - 2, 21));
        const endDate = new Date(Date.UTC(year, month - 1, 20));
        const dateRange = [];

        for (let date = new Date(startDate); date <= endDate; date.setUTCDate(date.getUTCDate() + 1)) {
            dateRange.push(new Date(date.getTime()));
        }

        return dateRange;
    };

    const generateAttendanceTable = () => {
        const allDates = generateDateRange(selectedYear, selectedMonth);
        return allDates.map(date => {
            const formattedDate = date.toISOString().split('T')[0];
            const attendanceRecord = attendanceData.find(record => record.date === formattedDate);
            const holidayName = getHolidayName(formattedDate);
            const isSundayFlag = isSunday(date);

            let loginTime = '-';
            let logoutTime = '-';

            if (attendanceRecord) {
                loginTime = attendanceRecord.start_time || '-';
                logoutTime = attendanceRecord.end_time || '-';
            }

            if (holidayName) {
                loginTime = holidayName;
                logoutTime = '';
            } else if (isSundayFlag) {
                loginTime = 'Sunday';
                logoutTime = '';
            }

            return {
                date: formattedDate,
                loginTime: loginTime,
                logoutTime: logoutTime,
                note: isSundayFlag || holidayName ? (isSundayFlag ? 'Sunday' : holidayName) : '',
            };
        });
    };
    useEffect(() => {
        const checkToken = async () => {
    
    
          if (!token) {
            navigate('/');
            return;
          }
    
          try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
              method: 'POST',
              headers: {
    
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }),
            });
    
            const data = await response.json();
    
            if (data.isValid) {
              
            } else {
              localStorage.removeItem('adminToken')
              
              navigate('/');
            }
          } catch (error) {
            localStorage.removeItem('adminToken')
    
           
            navigate('/');
          }
        };
    
        checkToken();
      }, [navigate]);
    const renderCalendar = () => {
        return (
            <div className="calendar-container">
                <select
                    className="year-select"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                >
                    {[2022, 2023, 2024, 2025].map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>

                <select
                    className="month-select"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                >
                    {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(
                        (month, index) => (
                            <option key={index + 1} value={index + 1}>
                                {month}
                            </option>
                        )
                    )}
                </select>
            </div>
        );
    };


    const attendanceRows = generateAttendanceTable();

    return (
        <div className="admin-attendance-container">
        <h2>Attendance Records</h2>
        {renderCalendar()}

        <div className="attendance-table-container">
            {attendanceRows.length > 0 ? (
                <table className="attendance-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Login Time</th>
                            <th>Logout Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceRows.map((item, index) => (
                            <tr key={index} className={item.note ? 'highlight' : ''}>
                                <td>{new Date(item.date).toLocaleDateString('en-IN')}</td>
                                {item.note ? (
                                    <td colSpan={2}>{item.note}</td>
                                ) : (
                                    <>
                                        <td>{item.loginTime}</td>
                                        <td>{item.logoutTime}</td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No attendance data available for this month.</p>
            )}
        </div>
    </div>
    );
}
