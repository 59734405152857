import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'; // Import moment.js for date formatting
import { useNavigate } from 'react-router-dom';

export default function Events() {
  const [holidays, setHolidays] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newHoliday, setNewHoliday] = useState({ date: '', originalDate: '', name: '', day: '' });
  const token = localStorage.getItem('adminToken');
  const navigate=useNavigate('')
  useEffect(() => {
      const checkToken = async () => {
  
  
        if (!token) {
          navigate('/');
          return;
        }
  
        try {
          const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
            method: 'POST',
            headers: {
  
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
  
          const data = await response.json();
  
          if (data.isValid) {
            
          } else {
            localStorage.removeItem('adminToken')
            
            navigate('/');
          }
        } catch (error) {
          localStorage.removeItem('adminToken')
  
         
          navigate('/');
        }
      };
  
      checkToken();
    }, [navigate]);
  useEffect(() => {
    // Fetch holidays from the backend server
    axios.get('https://mrv1.indianwelfarefoundation.org.in/api/holidays',{
      headers: {
          'Authorization': `Bearer ${token}`
      }
  })
      .then(response => {
        setHolidays(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the holidays!', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'date') {
      const formattedDate = moment(value).format('DD-MMM'); // Format date as "25-Dec"
      const day = moment(value).format('dddd'); // Get day of the week (e.g., "Wednesday")
      setNewHoliday({ ...newHoliday, originalDate: value, date: formattedDate, day: day });
    } else {
      setNewHoliday({ ...newHoliday, [name]: value });
    }
  };

  const addHoliday = () => {
    if (newHoliday.date && newHoliday.name) {
      const updatedHolidays = [...holidays, { id: holidays.length + 1, ...newHoliday }];
      setHolidays(updatedHolidays);

      // Update the backend database
      axios.post('https://mrv1.indianwelfarefoundation.org.in/api/holidays', newHoliday,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
          setNewHoliday({ date: '', originalDate: '', name: '', day: '' });
        })
        .catch(error => {
          console.error('There was an error adding the holiday!', error);
        });
    }
  };

  const deleteHoliday = (id) => {
    const updatedHolidays = holidays.filter(holiday => holiday.id !== id);
    setHolidays(updatedHolidays);

    // Update the backend database
    axios.delete(`https://mrv1.indianwelfarefoundation.org.in/api/holidays/${id}`,{
      headers: {
          'Authorization': `Bearer ${token}`
      }
  })
      .catch(error => {
        console.error('There was an error deleting the holiday!', error);
      });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="events">
      <div className="card" onClick={toggleModal}>
        <h2>Holidays</h2>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <h2>Holiday List</h2>
            <table className="holiday-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Holiday Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {holidays.map((holiday, index) => (
                  <tr key={holiday.id}>
                    <td>{holiday.date}</td>
                    <td>{holiday.day}</td>
                    <td>{holiday.name}</td>
                    <td>
                      <button className="delete-button" onClick={() => deleteHoliday(holiday.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <input
                      type="date"
                      name="date"
                      value={newHoliday.originalDate}
                      onChange={handleInputChange}
                      className="input-field"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="day"
                      value={newHoliday.day}
                      readOnly
                      className="input-field"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="name"
                      value={newHoliday.name}
                      onChange={handleInputChange}
                      placeholder="Holiday Name"
                      className="input-field"
                    />
                  </td>
                  <td>
                    <button className="add-button" onClick={addHoliday}>Add</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
