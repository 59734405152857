import React, { useState, useEffect } from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';
export default function Adminemployees({ onViewActivity }) {
    const [showModal, setShowModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState('All');
  
    const [formData, setFormData] = useState({
        employeename: '',
        employeephone: '',
        employeeref: '',
        employeeemail: '',
        emp_id: '',

        position: ''
    });
    const token = localStorage.getItem('adminToken') || 
    localStorage.getItem('teamLeaderToken') || 
    localStorage.getItem('employeeToken');

    useEffect(() => {
        fetch('https://mrv1.indianwelfarefoundation.org.in/employees',
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setEmployees(data);
                filterByPosition('All', data);
            })
            .catch(error => console.error('Error fetching employees:', error));
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const navigate=useNavigate('')
    // useEffect(() => {
    //     const checkToken = async () => {
    
    
    //       if (!token) {
    //         navigate('/');
    //         return;
    //       }
    
    //       try {
    //         const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
    //           method: 'POST',
    //           headers: {
    
    //             'Content-Type': 'application/json',
    //           },
    //           body: JSON.stringify({ token }),
    //         });
    
    //         const data = await response.json();
    
    //         if (data.isValid) {
              
    //         } else {
    //           localStorage.removeItem('adminToken')
              
    //           navigate('/');
    //         }
    //       } catch (error) {
    //         localStorage.removeItem('adminToken')
    
           
    //         navigate('/');
    //       }
    //     };
    
    //     checkToken();
    //   }, [navigate]);
    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://mrv1.indianwelfarefoundation.org.in/employees/count',
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(countData => {
                const emp_id = `T${countData.count + 1}`; // This will generate the emp_id if not provided
                const finalFormData = formData.emp_id ? formData : { ...formData, emp_id };

                return fetch('https://mrv1.indianwelfarefoundation.org.in/employees', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization':`Bearer ${token}`
                    },
                    body: JSON.stringify(finalFormData),
                });
            })
            .then(response => response.json())
            .then(newEmployee => {
                const updatedEmployees = [...employees, newEmployee];
                setEmployees(updatedEmployees);
                filterByPosition(selectedPosition, updatedEmployees);
                setShowModal(false);
            })
            .catch(error => console.error('Error adding employee:', error));
    };

    const handlePositionChange = (Position) => {
        setSelectedPosition(Position);
        filterByPosition(Position, employees);
        setFormData({ ...formData, position: Position });
    };

    const filterByPosition = (Position, employeeList) => {
        if (Position === 'All') {
            setFilteredEmployees(employeeList);
        } else {
            const filtered = employeeList.filter(employee => employee.Position === Position);
            setFilteredEmployees(filtered);
        }
    };

    return (
        <div className="admin-employee">
            <div className="top-bar">
                <button className="register-button" onClick={() => setShowModal(true)}>
                    Register Employee
                </button>
            </div>

            <div className="position-filter">
                <button className={`filter-button ${selectedPosition === 'XML' ? 'active' : ''}`} onClick={() => handlePositionChange('XML')}>XML Team</button>
                <button className={`filter-button ${selectedPosition === 'React js' ? 'active' : ''}`} onClick={() => handlePositionChange('React js')}>React JS</button>
                <button className={`filter-button ${selectedPosition === 'AwsDevops' ? 'active' : ''}`} onClick={() => handlePositionChange('AwsDevops')}>AwsDevops</button>
                <button className={`filter-button ${selectedPosition === 'Testing' ? 'active' : ''}`} onClick={() => handlePositionChange('Testing')}>Testing</button>
                <button className={`filter-button ${selectedPosition === 'Python' ? 'active' : ''}`} onClick={() => handlePositionChange('Python')}>Python</button>
                <button className={`filter-button ${selectedPosition === 'All' ? 'active' : ''}`} onClick={() => handlePositionChange('All')}>All</button>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={() => setShowModal(false)}>&times;</span>
                        <h2>Register Employee</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="employeename"
                                placeholder="Name"
                                value={formData.employeename}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="employeephone"
                                placeholder="Phone"
                                value={formData.employeephone}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="employeeref"
                                placeholder="Reference"
                                value={formData.employeeref}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="email"
                                name="employeeemail"
                                placeholder="Email"
                                value={formData.employeeemail}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="emp_id"
                                placeholder="Employee ID"
                                value={formData.emp_id}
                                onChange={handleInputChange}
                                style={{ marginBottom: '10px' }}
                            />
                            <select
                                name="position"
                                value={formData.position}
                                onChange={handleInputChange}
                                required
                                style={{
                                    padding: '5px',
                                    marginBottom: '10px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                }}
                            >
                                <option value="" disabled>Select Position</option>
                                <option value="XML">XML Team</option>
                                <option value="React js">React JS</option>
                                <option value="AwsDevops">AwsDevops</option>
                                <option value="Testing">Testing</option>
                                <option value="Python">Python</option>
                            </select>
                            <button type="submit">Register</button>
                        </form>
                    </div>
                </div>
            )}

            <div className="teamleader-table">
                <table>
                    <thead>
                        <tr style={{backgroundColor:'lightgreen'}}>
                            <th style={{backgroundColor:'green'}}>Name</th>
                            <th style={{backgroundColor:'green'}}>Employee ID</th>
                            <th style={{backgroundColor:'green'}}>Reference</th>
                            <th style={{backgroundColor:'green'}}>Email</th>
                            <th style={{backgroundColor:'green'}}>Position</th>
                            <th style={{backgroundColor:'green'}}>Activity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEmployees.map((employee, index) => (
                            <tr key={index}>
                                <td>{employee.employeename}</td>
                                <td>{employee.emp_id}</td>
                                <td>{employee.EmployeeReference}</td>
                                <td>{employee.employeeemail}</td>
                                <td>{employee.Position}</td>
                                <td>
                                    <button onClick={() => onViewActivity(employee)}>
                                        View Activity
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
