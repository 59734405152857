import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

export default function Login() {
    const [activeForm, setActiveForm] = useState('admin');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [empEmail, setEmpEmail] = useState('');
    const [empPassword, setEmpPassword] = useState('');
    const [teamLeaderEmail, setTeamLeaderEmail] = useState('');
    const [teamLeaderPassword, setTeamLeaderPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if any of the role-specific tokens exist and navigate accordingly
        const adminToken = localStorage.getItem('adminToken');
        const teamLeaderToken = localStorage.getItem('teamLeaderToken');
        const employeeToken = localStorage.getItem('employeeToken');

        if (adminToken) {
            navigate('/Admindashboard');
        } else if (teamLeaderToken) {
            navigate('/Teamleaderdashboard');
        } else if (employeeToken) {
            navigate('/Employeedashboard');
        }
    }, [navigate]);
    const updateEmployeeStatus = async (emp_id, status) => {
        try {
            const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/updateEmployeeStatus', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    emp_id: emp_id,
                    activestatus: status,
                }),
            });
    
            if (response.ok) {
                console.log(`Employee status updated to: ${status}`);
            } else {
                console.error('Failed to update employee status.');
            }
        } catch (error) {
            console.error('Error while updating employee status:', error);
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        let apiUrl = '';
        let requestBody = {};

        switch (activeForm) {
            case 'admin':
                apiUrl = 'https://mrv1.indianwelfarefoundation.org.in/Adminlogin';
                requestBody = { AdminEmail:adminEmail, AdminPassword:adminPassword };
                break;
            case 'team_leader':
                apiUrl = 'https://mrv1.indianwelfarefoundation.org.in/teamleaderlogin';
                requestBody = { teamleaderEmail:teamLeaderEmail, teamleaderPassword:teamLeaderPassword };
                break;
            case 'employee':
                apiUrl = 'https://mrv1.indianwelfarefoundation.org.in/employeelogin';
                requestBody = { employeeemail:empEmail,emppassword:empPassword };
                break;
            default:
                return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                const { token, user } = data;

                // Store the appropriate token in local storage
                switch (activeForm) {
                    case 'admin':
                        localStorage.setItem('adminToken', token);
                        localStorage.setItem('adminid',user.emp_id)
                        localStorage.setItem('adminname',user.AdminName)
                       console.log(user.emp_id)
                        navigate('/Admindashboard');
                        break;
                    case 'team_leader':
                        localStorage.setItem('teamLeaderToken', token);
                        localStorage.setItem('teamid',user.emp_id)
                        localStorage.setItem('teamleadername',user.teamleadername)
                        navigate('/Teamleaderdashboard');
                        break;
                    case 'employee':
                        if (user.passwordstatus === 'notverified') {
                            localStorage.setItem('password',user.emppassword)
                            const a = "hello"
                            localStorage.setItem('hello',a)
                            // Navigate to the Reset Password page
                            navigate('/Resetpassword');
                        } else {
                        localStorage.setItem('employeeToken', token);
                        localStorage.setItem('employeeid',user.emp_id)
                        localStorage.setItem('employeename',user.employeename)
                        updateEmployeeStatus(user.emp_id, 'active');
                        navigate('/Employeedashboard');}
                        break;
                    default:
                        return;
                }
            } else {
                alert(data.message || 'Login failed');
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    const renderForm = () => {
        switch (activeForm) {
            case 'admin':
                return (
                    <div className="form-container">
                        <h2>Admin Login</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            placeholder="Admin Email"
                            value={adminEmail}
                            onChange={(e) => setAdminEmail(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Admin Password"
                            value={adminPassword}
                            onChange={(e) => setAdminPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Login</button>
                    </form>
                  </div>
                );
            case 'team_leader':
                return (
                    <div className="form-container">
                         <h2>Team Leader Login</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            placeholder="Team Leader Email"
                            value={teamLeaderEmail}
                            onChange={(e) => setTeamLeaderEmail(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Team Leader Password"
                            value={teamLeaderPassword}
                            onChange={(e) => setTeamLeaderPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Login </button>
                    </form></div>
                );
            case 'employee':
                return (
                    <div className="form-container">
                         <h2>Employee Login</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            placeholder="Employee Email"
                            value={empEmail}
                            onChange={(e) => setEmpEmail(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Employee Password"
                            value={empPassword}
                            onChange={(e) => setEmpPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Login</button>
                    </form></div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-options">
                    <button
                        className={activeForm === 'admin' ? 'active' : ''}
                        onClick={() => setActiveForm('admin')}
                    >
                        Admin Login
                    </button>
                    <button
                        className={activeForm === 'team_leader' ? 'active' : ''}
                        onClick={() => setActiveForm('team_leader')}
                    >
                        Team Leader Login
                    </button>
                    <button
                        className={activeForm === 'employee' ? 'active' : ''}
                        onClick={() => setActiveForm('employee')}
                    >
                        Employee Login
                    </button>
                </div>
                <div className="login-form">
                    {renderForm()}
                </div>
            </div>
        </div>
    );
}
